import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "../App.css"

function ProyectoTameplate() {



    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={[
                    "https://assets-global.website-files.com/619e933166d45de9c8877b50/61cb730a39c94d781d92ffe0_slide3_alfonso_2.jpeg",
                    "https://assets-global.website-files.com/619e933166d45d327c877b79/61cd31b7caa2663baeb5d370_Esencia_039.jpg",
                    "https://assets-global.website-files.com/619e933166d45d327c877b79/65d4cf23165cf9daaa953e65_Esencia_457.jpg",
                    "https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32528cdf721e2c508f66_Esencia_197.jpg"

                ]}
                slide={lightboxController.slide}
            />

            <section>
                <div className=" d-flex align-items-center justify-content-center pb-5">
                    <h6 className="lh-0 m-0 text-uppercase ls-0_5px opacity-50"><a href="/proyectos">Proyectos</a></h6>
                    <span class="material-symbols-rounded h4 p-0 m-0 fw-bold opacity-50">
                        chevron_right
                    </span>
                    <h6 className="lh-0 m-0 text-uppercase ls-0_5px">
                        Hotel Escencia
                    </h6>

                </div>
            </section>

            <div className="container-fluid position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src="https://assets-global.website-files.com/619e933166d45de9c8877b50/61cb730a39c94d781d92ffe0_slide3_alfonso_2.jpeg" className="w-100" alt="..." height={650} />
            </div>


            <div className="container-fluid">
                <div className="row gy-3 gx-3 mt-1">
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd31b7caa2663baeb5d370_Esencia_039.jpg"
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src="https://assets-global.website-files.com/619e933166d45d327c877b79/65d4cf23165cf9daaa953e65_Esencia_457.jpg"
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src="https://assets-global.website-files.com/619e933166d45d327c877b79/61cd32528cdf721e2c508f66_Esencia_197.jpg"
                            className="w-100 h-100"
                            alt="Wintry Mountain Landscape"
                        />
                    </div>
                </div>
            </div>




        </>
    );
}

export default ProyectoTameplate;
